import type { FilterIds } from '~/types/FilterIds'
import { computed } from 'vue'
import { useServiceSearchStore } from '~/stores/serviceSearchStore'

export interface AgeBracketDefinition {
  min: number
  max: number
  label: string
  key: FilterIds
  filterId: FilterIds
  forceAgeVerified: boolean
  isSelectedFn: (ageRange: { min: number; max: number }) => boolean
}

/**
 * child (0-12)
 * teen (12-17)
 * young adult (18-24)
 * Child or Young Person (0-24)
 * adult (25-59)
 * senior (50+)
 */
export const youthAgeRange = [0, 25]
export const adultAgeRange = [26, 50]

export const pwaAgeBrackets = (): AgeBracketDefinition[] => [
  {
    min: 0,
    max: 11,
    label: 'Children (Under 12)',
    key: 'age-bracket:child',
    filterId: 'age-bracket:child',
    forceAgeVerified: true,
    isSelectedFn: ({ min }) => min < 12
  },
  {
    min: 12,
    max: 17,
    label: 'Teens (12-17)',
    key: 'age-bracket:teen',
    filterId: 'age-bracket:teen',
    forceAgeVerified: true,
    isSelectedFn: ({ min, max }) => min < 18 && max > 11
  },
  {
    min: 19,
    max: 25,
    label: 'Young Adults (18-25)',
    key: 'age-bracket:young-adult',
    filterId: 'age-bracket:young-adult',
    forceAgeVerified: true,
    isSelectedFn: ({ min, max }) => min < 26 && max > 17
  },
  {
    min: 19,
    max: 122,
    label: 'Adult (18+)',
    key: 'age-bracket:adult',
    filterId: 'age-bracket:adult',
    forceAgeVerified: false,
    // this one is different because it has an overlap with the young adult bracket
    // we don't want this one to be selected if the max age is less than 26
    isSelectedFn: ({ max }) => max > 25
  },
  {
    min: 50,
    max: 122,
    label: 'Older Adult (50+)',
    key: 'age-bracket:senior',
    filterId: 'age-bracket:senior',
    forceAgeVerified: false,
    isSelectedFn: ({ max }) => max > 49
  }
]

// admin age brackets the same
export const ageBrackets = () => pwaAgeBrackets()

const ABSOLUTE_MIN_AGE = 0
const ABSOLUTE_MAX_AGE = 122

const createDefaultSelectedAgeBrackets = (pwa = false) => {
  const { filters } = useServiceSearchStore()
  const currentAgeRangeFilters = filters.ageRange
  const [min, max] = currentAgeRangeFilters

  // Choose the appropriate age brackets based on the pwa flag
  const bracketsToUse = pwa ? pwaAgeBrackets() : ageBrackets()

  let selectedAgeBrackets = Object.fromEntries(
    bracketsToUse.map((ageBracket) => {
      // Determine the selected state of the age bracket from ageRange filters in store
      // If there are no ageRange filters in place (meaning [0, 122]) then all age brackets are unselected
      const isSelected = ageBracket.isSelectedFn({ min, max })
      return [ageBracket.key, isSelected]
    })
  )

  // If all age brackets are within range, consider this an un-filtered result and unselect all
  if (Object.values(selectedAgeBrackets).every((isSelected) => isSelected)) {
    selectedAgeBrackets = Object.fromEntries(
      bracketsToUse.map((ageBracket) => {
        return [ageBracket.key, false]
      })
    )
  }
  return selectedAgeBrackets
}

export const useAgeBrackets = (pwa = false) => {
  const store = useServiceSearchStore()
  const { selectedAgeBrackets } = store

  selectedAgeBrackets.value = createDefaultSelectedAgeBrackets(pwa)

  /** The min and max age based on the selected age brackets */
  const selectedAgeRange = computed(() => {
    // Choose the appropriate age brackets based on the pwa flag
    const bracketsToUse = pwa ? pwaAgeBrackets() : ageBrackets()

    const selectedKeys = Object.keys(selectedAgeBrackets.value).filter(
      (key) => selectedAgeBrackets.value[key]
    )
    const selectedAgeBracket = bracketsToUse.filter(({ key }) => selectedKeys.includes(key))
    if (!selectedAgeBracket.length) return { min: ABSOLUTE_MIN_AGE, max: ABSOLUTE_MAX_AGE }

    const min = Math.min(...selectedAgeBracket.map(({ min }) => min))
    const max = Math.max(...selectedAgeBracket.map(({ max }) => max))

    return { min, max }
  })

  return {
    // Return the appropriate age brackets based on the pwa flag
    ageBrackets: pwa ? pwaAgeBrackets() : ageBrackets(),
    // selectedAgeBrackets,
    selectedAgeRange
  }
}
